html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #f7f7f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
  overflow: auto;
  display: block;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: dark gray light gray; /* For Firefox */
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard {
  margin-top: 200px;
  margin-left: 200px;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}