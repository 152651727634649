.home-page {
    .text-zone {
      position: absolute;
      left: 30%;
      top: 30%;
      transform: translateY(-50%);
      width: 60%;
      width: 60%;
      max-height: 90%;
      
    }
  
    h1 {
      color: #fff;
      font-size:26px;
      line-height: 53px;
      
      margin: 0;
      font-family: 'Roboto';
      font-weight: 200;
  
      &:before {
        // content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffffff;
        font-size: 18px;
        position: absolute;
        margin-top: -40px;
        left: -15px;
        opacity: 0.6;
      }
  
      &:after {
        // content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffffff;
        font-size: 18px;
        position: absolute;
        margin-top: 18px;
        margin-left: 20px;
        animation: fadeIn 1s 1.7s backwards;
        opacity: 0.6;
      }
  
      img {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        justify-content: center;
        opacity: 0;
        width: 192px;
        height: 46px;
        animation: fadeIn 1s linear both;
        animation-delay: 1.4s;
      }
    }
  
    h2 {
      color: #c5c5c5;
      margin-top: 20px;
      font-weight: 400;
      font-size: 11px;
      font-family: 'Roboto';
      letter-spacing: 2px;
      animation: fadeIn 1s 1.8s backwards;
    }
  
    .flat-button {
      color: #ffffff;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #ffffff;
      border-radius: 00%;
      margin-top: 25px;
      float: left;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;
  
      &:hover {
        background: #ffffff;
        color: #333;
      }
    }
  }